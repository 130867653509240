import React, {render} from "react";
import SvgIcon from "../reactComponents/SvgIcon";

import {Q, C} from '../Jesus';
import Events from "../events";
import {parseQuery, toQueryString} from "../functions";

const noop = function () {
};

export default () => {

    const events = new Events;
    const shopContainer = Q('.shop-container');
    const pParam = 'pager_start';
    const loadingWrapper = Q('#loading-wrapper')
    const loadingData = loadingWrapper.dataset;

    return {
        pCount: loadingData.itemsPerPage && parseInt(loadingData.itemsPerPage) || 50,
        pParam: pParam,
        events: events,
        hiddenParams: {},
        url: loadingData.url,
        currPage: 1,
        totalItems: parseInt(loadingData.itemsTotal),
        stripFromUrl: ['pclass'], // params to strip from visible url
        loading: false,

        init() {

            this.updateData();
            this.renderNav();
            loadingWrapper.Html('');
            var t = this;

            return this;
        },

        update() {
            this.loading = false;
            this.updateData();
            this.renderNav()
        },

        setData(url, totalItems) {
            this.url = url;
            this.totalItems = totalItems;

            return this;
        },

        get path() {

            const u = loadingData.url;
            return u.length &&
                u.substring(0, u.indexOf('?')) ||
                false;
        },

        get nextOffset() {

            var offset = parseQuery(
                this.url
            );

            offset = offset && offset[pParam] && parseInt(offset[pParam][0]);

            if (offset === this.offset || !offset) {
                return this.offset + this.pCount
            } else {
                return offset;
            }
        },

        get offset() {
            var offset = location.search && parseQuery(location.search);

            offset = offset && offset[pParam] && parseInt(offset[pParam][0]) || 0;

            return offset;
        },

        updateData: function () {
            this.currPage = Math.ceil(this.nextOffset / this.pCount);
            this.totalItems = this.totalItems && parseInt(this.totalItems) || this.pCount * this.currPage;
        },

        navigate: function (action, e) {

            if (this.loading) return;

            var clicked = e && e.target && (
                this.nav.bottom.inner.contains(e.target) && 'bottom' ||
                this.nav.top.inner.contains(e.target) && 'top'
            );

            var url = parseQuery(this.url);

            switch (action) {

                case 'next':
                    this.currPage++;
                    url[pParam] = [this.nextOffset];
                    break;

                case 'prev':
                    this.currPage--;
                    url[pParam] = [this.offset - this.pCount];
                    break;

                case 'get':
                    url[pParam] = [this.offset];
                    break;
            }

            var query = {
                ...url,
                ...this.hiddenParams
            };

            this.stripFromUrl.forEach(function (param) {
                if (param in url) {
                    delete url[param]
                }
            });

            query = toQueryString(query);
            url = toQueryString(url);

            if (action === 'get') return {
                query: '?' + query,
                url: '?' + url
            };

            clicked === 'bottom' &&
            window.scrollTo(0, window.scrollY + this.nav.top.inner.getBoundingClientRect().top - 160);

            this.loading = true;

            // Picked up by filter
            this.events.fireEvent('navigate', {
                url: '?' + url,
                query: '?' + query
            });
        },

        nav: (function () {

            const ret = {
                top: {},
                bottom: {}
            };

            C('div', {'class': 'pager-nav-wrap pager-nav-wrap-top'}).Append(
                C('nav', {'class': 'pager-nav pager-nav-top'}).Cache(ret.top, 'inner')
            ).InsertBefore(shopContainer).Cache(ret.top, 'wrapper');

            C('div', {'class': 'pager-nav-wrap pager-nav-wrap-bottom'}).Append(
                C('nav', {'class': 'pager-nav pager-nav-bottom'}).Cache(ret.bottom, 'inner')
            ).InsertAfter(shopContainer).Cache(ret.bottom, 'wrapper');

            return ret;
        })(),

        renderNav: function () {

            var t = this,
                offset = t.offset,
                nextOffset = t.nextOffset,
                totPages = Math.ceil(t.totalItems / t.pCount);

            var prevClass = ['prev'],
                nextClass = ['next'];

            var prevDisabled = !offset && prevClass.push('disabled') | 1;
            var nextDisabled = this.currPage === totPages && nextClass.push('disabled') | 1;

            nextClass = nextClass.join(' ');
            prevClass = prevClass.join(' ');

            function createInner() {

                var prev = C('button', {'type': 'button', 'class': prevClass, 'aria-label': 'Forrige side'}).Call(function () {
                        this.onclick = !prevDisabled && t.navigate.bind(t, 'prev') || noop;
                    }),
                    next = C('button', {'type': 'button', 'class': nextClass, 'aria-label': 'Neste side'}).Call(function () {
                        this.onclick = !nextDisabled && t.navigate.bind(t, 'next') || noop
                    }),
                    current = C('div', {'class': 'current-page'}).Append(
                        C('span').Text([((offset + t.pCount) / t.pCount), totPages].join('/'))
                    );

                render(<SvgIcon name={'chevron_left'}/>, prev);
                render(<SvgIcon name={'chevron_right'}/>, next);

                return {
                    prev: prev,
                    next: next,
                    current: current
                }
            }

            var top = createInner(),
                bottom = createInner();

            this.nav.top.inner.Html('').Append(
                top.prev,
                top.current,
                top.next
            );

            this.nav.bottom.inner.Html('').Append(
                bottom.prev,
                bottom.current,
                bottom.next
            );
        }
    }
};
