import React, {render} from 'react';
import frameStore from "./frameStore";
import FramePicker from "../reactComponents/Frames/FramePicker";
import {C} from "../Jesus";
import EppoScroll from "../eppoScroll";

const framePicker = (token) => {

    const {element, data} = token;

    const {
        initial_framing_type,
        framing_types,
        ...initialState
    } = data;

    if (initial_framing_type == -1)
        return;

    frameStore.setState({
        ...initialState,
        framing_type: framing_types.find(f => f.value === initial_framing_type),
    });

    render(<FramePicker store={frameStore} types={framing_types}/>, element)

    const panel = element.closest('.panel.frame');

    const elements = {};
    panel.Append(
        C('div', {'class': 'pane-scroll'}).Cache(elements, 'scroll')
            .Append(...panel.children)
    )

    new EppoScroll(elements.scroll);

    return token;
}

export default framePicker;
