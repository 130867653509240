import SimpleSlider from "../components/simpleSlider";

export default (token) => {

    const {element, data = {}} = token;

    const $container = element.Q('.catNav-inner');
    const $wrapper = element.Q('.catNav-entries');
    const $entries = element.QA('.catNav-entry');
    const $active = element.Q('.catNav-entry_active');


    var initIndex      = $entries.findIndex(el => el.classList.contains('catNav-entry_active'));
    const initialIndex = ((initIndex == -1) ? 0 : initIndex);

    const slider = new SimpleSlider($wrapper, {
        centered: true,
        snap: false,
        initialIndex,
    });

    const [$prev, $next] = [
        element.Q('.catNav-arrow_prev'),
        element.Q('.catNav-arrow_next')
    ];

    const step = window.innerWidth > 1200 ? 5
        : window.innerWidth > 1000 ? 4
        : window.innerWidth > 850 ? 3
        : window.innerWidth > 400 ? 2
        : 1;

    const next = () => slider.goTo(slider.activeIndex + step);
    const prev = () => slider.goTo(slider.activeIndex - step);

    [prev, next].forEach((fn, i) => [$prev, $next][i].addEventListener('click', fn));

    slider.on('atEdge', setStatus);

    const disabledArrowClass = 'catNav-arrow_disabled'

    function setStatus() {

        slider.atStart
        && $prev.AddClass(disabledArrowClass)
        || $prev.RemoveClass(disabledArrowClass);

        slider.atEnd
        && $next.AddClass(disabledArrowClass)
        || $next.RemoveClass(disabledArrowClass);
    }

    setStatus();
    initialIndex && slider.goTo(initialIndex, 0);
}
