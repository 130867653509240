<template>
  <div>
    <div class="embla relative">
      <div class="embla__viewport overflow-hidden" ref="emblaNode">
        <div class="embla__container flex items-start">
          <slot></slot>
        </div>
      </div>

      <div v-if="navigation"
           class="absolute w-full z-10 justify-between flex items-center"
           :class="navigationClass"
      >
        <button type="button" @click="scrollPrev" class="embla__prev bg-black rounded-full p-2" title="Forrige"
                :class="navigationButtonPrevClass"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 stroke-neutral-300">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
          </svg>
        </button>
        <button type="button" @click="scrollNext" class="embla__next bg-black rounded-full p-2" title="Neste"
                :class="navigationButtonNextClass"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 stroke-neutral-300">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
          </svg>
        </button>
      </div>

      <div class="embla__dots space-x-2 flex justify-center my-4">
        <button
            type="button"
            v-for="(dot, index) in dots"
            @click="scrollTo(index)"
            class="w-6 h-2 rounded-full focus-visible:ring-0 focus:outline-none "
            :class="selectedIndex === index ? 'bg-midnight' : 'bg-neutral-300'"
            :key="index"
            :title="'Valg ' + (index + 1)"
        >
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import emblaCarouselVue from "embla-carousel-vue";
import AutoHeight from 'embla-carousel-auto-height'

const props = defineProps({
  navigation: Boolean,
  navigationClass: String,
  navigationButtonPrevClass: String,
  navigationButtonNextClass: String
})

const selectedIndex = ref(0);

const options = { destroyHeight: 'auto' }

const [emblaNode, emblaApi] = emblaCarouselVue({ loop: false }, [AutoHeight(options)]);

const scrollTo = (index) => emblaApi.value?.scrollTo(index);
const dots = computed(() => emblaApi.value?.scrollSnapList() || []);
const scrollPrev = () => emblaApi.value?.scrollPrev()
const scrollNext = () => emblaApi.value?.scrollNext()


const onSelect = () => {
  if (!emblaApi.value) return;
  selectedIndex.value = emblaApi.value.selectedScrollSnap();
};

onMounted(() => {
  if (!emblaApi.value) return;
  emblaApi.value.on("select", onSelect);
  onSelect();
});
</script>
