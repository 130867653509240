/**
 * Created by Samsung on 19.02.2018.
 */
import {roundHalf} from "./functions";
import {C, Q} from './Jesus';

const rulers = (frameRoot) => {

    var workshop = Q('.workshop');
    if(!workshop) return null;

    var frame = frameRoot,
        frameContainer = frame.parentElement.AddClass('frame-container'),
        rulers = {},
        rulerHeight,
        rulerWidth,
        lastHeight,
        lastWidth,
        t = {};

    C('div', {'class': 'ruler ruler-width'}).Cache(rulers, 'widthWrap').PrependTo(frameContainer)
        .Append(C('div', {'class': 'ruler-text'}).Cache(rulers, 'w'));
    C('div', {'class': 'ruler ruler-height'}).Cache(rulers, 'heightWrap').PrependTo(frameContainer)
        .Append(C('div', {'class': 'ruler-text'}).Cache(rulers, 'h'));

    t.size = {};

    t.updateRulers = function(frameInstance) {

        const {data: frameData} = frameInstance;
        const {framingType, noFrame, frameGap, subjectGap, matW} = frameData;

        const data = {
            ...frameData,
            gap: frameGap || 0,
            subjectGap: !noFrame && subjectGap || 0,
            matW: (framingType === 0 || framingType === 3) && matW || 0,
            ...noFrame && {
                mat: 0,
                matW: 0,
                frameW: 0,
            } || {},
        }

        rulerWidth = t.size.x =
            data.frameW * 2 +
            data.matW * 2 +
            data.subjectGap * 2 +
            data.gap * 2 +
            (data.dim === 'medium' && !noFrame && data.mediumW || data.artW);

        rulerHeight = t.size.y =
            data.frameW * 2 +
            data.matW * 2 +
            data.subjectGap * 2 +
            data.gap * 2 +
            (data.dim === 'medium' && !noFrame && data.mediumH || data.artH);

        rulerHeight !== lastHeight &&
            (rulers.h.innerText = roundHalf(rulerHeight).toString().replace('.', ',') + ' cm');

        rulerWidth !== lastWidth &&
            (rulers.w.innerText = roundHalf(rulerWidth).toString().replace('.', ',') + ' cm');

        lastWidth = rulerWidth;
        lastHeight = rulerHeight;
    };

    return t;

};

export default rulers;
