export const benefits = [
    'Handle i nettgalleriet.',
    'By på auksjon.',
    'Magasinet KUNST i posten 4 ganger i året.',
    '10-40 % bonus på utvalgte kunstverk.',
    '20 % rabatt på utvalgte kunstverk.',
    '20 % rabatt på innramming.',
    '20 % rabatt på frakt.',
]

export const fav_benefits = [
    'Handle i nettgalleriet.',
    'By på auksjon.',
    'Magasinet KUNST i posten 4 ganger i året.',
    '10-40 % bonus på utvalgte kunstverk.',
    '20 % rabatt på utvalgte kunstverk.',
    '30 % rabatt på innramming.',
    '20 % rabatt på frakt.',
    'Ett gratis kunstverk i året (minimum galleripris 2000,-)',
    'Invitasjoner til utstillinger og arrangementer.',
    'Tilgang til ekslusiv kunst før alle andre.',
    'Subjekt Pluss-abonnement i 6 mnd. til kun kr 1,-. (verdi kr 1450,-.)',
]

export const plans = [
    {
        name: "Free",
        cgroup_id: "1000",
        price: "0",
        bg: "bg-free",
        benefits: benefits.slice(0, 2),
        tagline:"For deg som er nysgjerrig på kunst"
    },
    {
        name: "Fan",
        cgroup_id: "2000",
        price: "595",
        bg: "bg-fan",
        benefits: benefits.slice(0, 7),
        tagline:"For deg som er opptatt av kunst"
    },
    {
        name: "Favourite",
        cgroup_id: "3000",
        price: "2495",
        bg: "bg-favourite",
        benefits: fav_benefits,
        tagline:"For deg som elsker kunst"
    },
];
