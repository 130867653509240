import basket from "../modules/userLists/basket";
import {modal} from "../modules/modal";
import CartAdded from "../reactComponents/CartAdded";
import {errToast} from "../modules/toaster";
import request from 'superagent';

let instance;

const noop = () => {
};

const createInstance = () => {

    var form = document.querySelector('#product_form');
    if (!form) return {};

    var btn = form.querySelector('#product_form_submit');

    if (!btn) return {};

    form.addEventListener('submit', noop);
    btn.addEventListener('click', submit);

    function submit(e) {

        e.preventDefault();
        const submitter = e.currentTarget;
        const payload = new FormData(form);

        submitter.name && submitter.value && payload.append(
            submitter.name, submitter.value
        )

        request.post(form.action)
            .send(payload)
            .accept('application/json')
            .then(res => res.body)
            .then((res) => {
		var obj, price, tot_value, items;

                basket.setState({count: res.count});

                modal.setComponent({
                    Component: CartAdded,
                    props: {...res}
                })
                    .open();

		items		= [];
		tot_value	= 0;
		if (res.item != null) {
			obj			= res.item;
			price			= parseFloat(obj.price_inc.replace(',00', '').replace(' ', '').replace(',', '.'));
			tot_value		+= price;
			items[items.length]	= {index:items.length, item_id:obj.item_id, item_name:obj.pname, item_brand:obj.brand_name, item_category:'Kunst', item_variant:obj.iname, price:price};
		}
		if (res.frame != null) {
			obj			= res.frame;
			price			= parseFloat(obj.price_inc.replace(',00', '').replace(' ', '').replace(',', '.'));
			tot_value		+= price;
			items[items.length]	= {index:items.length, item_id:obj.item_id, item_name:obj.pname, item_brand:obj.brand_name, item_category:'Kunst', item_variant:obj.iname, price:price};
		}
		if (res.glass != null) {
			obj			= res.glass;
			price			= parseFloat(obj.price_inc.replace(',00', '').replace(' ', '').replace(',', '.'));
			tot_value		+= price;
			items[items.length]	= {index:items.length, item_id:obj.item_id, item_name:obj.pname, item_brand:obj.brand_name, item_category:'Kunst', item_variant:obj.iname, price:price};
		}
		gtag('event', 'add_to_cart', {currency:'NOK',value:tot_value,items:items});
            })
            .catch(errToast);
    }

    instance = {
        form: form,
        btn: btn
    };

    return instance;
}

export default {
    getInstance: function () {
        return instance || createInstance();
    }
}
